<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="院内感染管理系统" describe="系统能够实现与医院现有系统的数据对接，自动完成HIS、LIS、PACS、电子病历、手术麻醉、护理系统等系统的数据加载，并自动完成各项院感数据匹配工作，为医院感染管理工作人员提供详实的数据支持。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="highlight">
        <div class="title">
          系统能够实现与医院现有系统的数据对接，自动完成HIS、LIS、PACS、电子病历、手术麻醉、护理系统等系统的数据加载，并自动完成各项院感数据匹配工作，为医院感染管理工作人员提供详实的数据支持。<br>
          可满足医院临床、医技科室、医院感染管理的要求，实时监控，在线监测，及时上报，杜绝漏报；主动预警，干预提示；及时沟通，控制为本。符合医院感染管理科的实际工作理念，能按照自动发现疑似病例→辅助确诊院感病例→协助与临床干预三步骤进行设计，各环节均按照院感实际工作实现，专职人员使用方便。<br>
          系统能实现感染病例管理、风险因素管理、外科手术监测、ICU监测、高危新生儿监测、现患率调查、消毒卫生学监测、抗菌药物监测、三级医院评审等众多内容，涵盖了医院感染监测全部业务，同时包含有目标性监测和控制，如外科手术部位的感染，导管相关血流感染，导尿管相关尿路感染，呼吸机相关肺炎、中心静脉插管相关血流感染等，也可实时监测全院医院感染相关数据的情况，提供医院感染监测暴发预警，可进行全国范围、全省范围、全市范围的医院感染数据网络直报。
        </div>
        <img src="@/assets/product/infect1.png" class="img1" alt="">
      </div>
      <div class="introduction-content">
        <img src="@/assets/product/infect2.png" class="img2" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .highlight {
    width: 100%;
    height: 540px;
    background: #eff6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    .title {
      width: 650px;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      margin-right: 110px;
    }
    .img1 {
      width: 430px;
      height: 430px;
    }
  }
  .introduction-content {
    width: 1594px;
    margin: 0 auto;
    .img2 {
      width: 100%;
      height: 850px;
    }
  }
}
</style>
